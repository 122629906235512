<template>
  <div class="foot"> Copyright© 2021 All Rights Reserved | 版权所有：至信链  | 
    <a
        target="_blank"
        href="http://www.beian.miit.gov.cn"
      >京ICP备16033891号-5 |</a>
      京网信备11010519496506010015
  </div>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style lang="scss" scoped>
.foot {
  font-size: 14px;
  color: rgb(178, 183, 191);
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 50px;
}
 a:link,
    a:visited,
    a:hover,
    a:active {
        color: rgb(178, 183, 191);
      font-size: 14px;
      text-decoration: none;
    }
</style>
